.my-suggestions-page {
  & > .ta-row {
    & > img {
      max-width: 844px;
      margin: 0 auto;
      margin-top: 4px;
      margin-bottom: 14px;
      @include mq(1199px, max) {
        width: 476px;
      }
      @include mq(999px, max) {
        width: 376px;
      }
      @include mq(799px, max) {
        width: 276px;
      }
      @include mq(699px, max) {
        display: none;
      }
    }
    & > .ta-card {
      & > .my-suggestions-container {
        display: flex;
        flex-direction: column;
        padding: 24px 8px !important;
        padding-bottom: 0px !important;
        & > .title {
          color: #2ba7df;
          font-size: 42px;
          font-weight: 600;
          @include mq(1099px, max) {
            font-size: 24px;
          }
        }
        & > .label {
          color: #0d0e0f;
          font-size: 24px;
          font-weight: 600;
          margin-bottom: 42px;
          @include mq(1099px, max) {
            font-size: 18px;
            margin-bottom: 12px;
          }
        }
        & > .section-title {
          color: #2ba7df;
          font-size: 16px;
          font-weight: 600;
          margin-bottom: 10px;
          @include mq(1099px, max) {
            font-size: 16px;
          }
        }
        & > .ta-form {
          & > .inputs-group {
            display: flex;
            @include mq(899px, max) {
              flex-direction: column;
            }
            & > .group {
              display: flex;
              flex-direction: column;
              width: calc(100% / 2 - 12px);
              @include mq(899px, max) {
                width: 100%;
              }
              & > .MuiFormControl-root {
                margin-top: 0;
              }
            }
            & > :first-child {
              margin-right: 24px;
            }
          }
        }
      }
    }
  }
}
