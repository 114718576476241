.ta-card {
  width: 100%;
  padding: 12px;
  box-shadow: 0px 2px 8px rgba(179, 208, 232, 0.6) !important;
  border-radius: 8px !important;
  & > .MuiCardHeader-root {
    padding: 0;
    margin-bottom: 8px;
    & > .MuiCardHeader-avatar {
      margin-right: 8px;
      & > .MuiSvgIcon-colorPrimary {
        color: $blue;
      }
      & > .MuiSvgIcon-colorSecondary {
        color: $orange;
      }
      & > .MuiSvgIcon-root {
        &.primary {
          color: #2ba7df;
        }
        &.secondary {
          color: #2ba7df;
        }
        &.success {
          color: #50bf88;
        }
        &.warning {
          color: #edc454;
        }
        &.danger {
          color: #ed5454;
        }
      }
    }
    & > .MuiCardHeader-action {
      margin-top: 0;
      margin-right: 0;
    }
  }
  & > .MuiCardContent-root {
    padding: 0 !important;
    display: flex;
    & > .child-left,
    .child-right,
    .child-full {
      margin-bottom: 12px;
    }
    & > .child-left {
      box-sizing: border-box;
      margin-right: 6px;
      width: calc(50% - 6px);
      @include mq(1380px, max) {
        width: 100%;
        margin-right: 0;
      }
    }
    & > .child-right {
      box-sizing: border-box;
      margin-left: 6px;
      width: calc(50% - 6px);
      @include mq(1380px, max) {
        width: 100%;
      }
    }
    & > .child-full {
      box-sizing: border-box;
      width: 100%;
    }
  }
  .list_post {
    display: flex;
    flex-direction: column;
    cursor: pointer;
  }
  .post-title {
    color: #18698d;
    font-size: 14px;
    font-family: tajawal;
    font-weight: bold;
    cursor: pointer;
  }
  .poster {
    color: #a2a2a7;
    opacity: 1;
    font-size: 12px;
    font-family: tajawal;
  }
  .post-content {
    color: #0d0e0f;
    opacity: 1;
    letter-spacing: 0;
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
    //white-space: nowrap;
    width: 100%;
    overflow: hidden;
  }
  .live-session-title {
    color: #ec5542;
    font-size: 16px;
    font-weight: bold;
    text-transform: none;
  }
  &.outlined {
    &.primary {
      border: 1px solid #2ba7df;
    }
    &.secondary {
      border: 1px solid #2ba7df;
    }
    &.success {
      border: 1px solid #50bf88;
    }
    &.warning {
      border: 1px solid #edc454;
    }
    &.danger {
      border: 1px solid #ed5454;
    }
  }
  &.ta-card-transparent {
    background: transparent;
    box-shadow: none !important;
    padding: 0;
    overflow: visible;
    & > .MuiCardHeader-root {
      & > .MuiCardHeader-avatar > svg {
        font-size: 24px !important;
      }
      & > .MuiCardHeader-content > .MuiTypography-root > .card-title {
        font-size: 16px;
        font-weight: 600;
      }
    }
    & > .ta-list {
      display: flex;
      flex-direction: column;
      & > *:not(:last-child) {
        margin-bottom: 12px;
      }
    }
  }
}
