.code-coupon-view {
  & > .ta-row {
    & > .ta-btn {
      width: fit-content;
    }
    & > .view-title.secondary {
      font-weight: unset;
      font-size: 24px;
    }
    & > .ta-card > .MuiCardContent-root {
      display: flex;
      flex-direction: column;
    }
    & > .alert {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 24px;
      & > .MuiAlert-message > .MuiTypography-root {
        margin: 0;
      }
    }
  }
}

.icon-with-text {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > .MuiSvgIcon-root {
    width: 14px;
    color: black;
    filter: drop-shadow(0px 0px 2px rgb(0 0 0 / 60%));
    margin-right: 12px;
    margin-left: 2px;
    &.success {
      color: #3ad579;
      filter: drop-shadow(0px 0px 4px #3ad579);
    }
    &.danger {
      color: #ed3f38;
      filter: drop-shadow(0px 0px 4px #ed3f38);
    }
  }
}

.icon-info {
  display: flex;
  align-items: center;
  justify-content: center;
}
.payment-history-icon{
  width:25px !important;
  height:25px !important;
  color:#0093e2 ;

}