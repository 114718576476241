.subject-item {
  background: #ffffff;
  padding: 12px;
  border-radius: 12px;
  border-top-left-radius: 0;
  position: relative;
  min-width: 200px;
  min-height: 120px;
  margin-top: 25px !important;
  margin-bottom: 45px;
  filter: drop-shadow(0px 2px 8px rgb(179 208 232 / 60%));
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  color: #2ba7df;
  &::before {
    content: '';
    width: 80px;
    height: 25px;
    background: #ffffff;
    position: absolute;
    top: -25px;
    left: 0;
    border-top-left-radius: 12px;
    z-index: -2;
  }
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    top: -15px;
    left: 68px;
    border-left: 18px solid transparent;
    border-right: 18px solid transparent;
    border-top: 18px solid #fff;
    transform: rotate(45deg);
    z-index: -2;
  }
  &.active {
    background: #2ba7df;
    color: #ffffff;
    &::before {
      background: #2ba7df;
    }
    &::after {
      border-top-color: #2ba7df;
    }
    & > img {
      // filter: brightness(100) sepia(1);
    }
  }
  & > img {
    width: 50px;
    height: 50px;
    position: absolute;
    top: -5px;
    left: 20px;
  }
  & > .name {
    font-size: 16px;
    font-weight: 500;
  }
}

.subject-classes {
  flex-direction: column;
  padding: 12px !important;
  & > .title {
    font-size: 20px;
    font-weight: 600;
  }
  & > .label {
    font-size: 17px;
    color: #a2a2a7;
    margin-bottom: 20px;
  }
}

.classe-item {
  width: calc(100% / 4 - 20px);
  margin-right: 16px;
  max-width: 156px;
  margin-bottom: 20px;
  height: 76px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 2px solid #95d3ef;
  border-radius: 12px;
  color: #1b6b8f;
  cursor: pointer;
  transition: all 300ms ease;
  &:hover {
    background: #daf3ff;
  }
}

.classes-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.subjects-page {
  & > .ta-row {
    & > .ta-column {
      @include mq(1200px, max) {
        padding-left: 0 !important;
      }
      @include mq(980px, max) {
        flex-direction: column-reverse !important;
      }
      & > .child-left {
        & > .MuiPaper-root {
          & > .MuiCardContent-root {
            @include mq(1260px, max) {
              & > .title {
                font-size: 14px;
              }
              & > .label {
                font-size: 14px;
              }
              & > .classes-container {
                & > .classe-item {
                  width: calc(100% / 3 - 20px);
                }
              }
            }
            @include mq(799px, max) {
              & > .classes-container {
                & > .classe-item {
                  width: calc(100% / 2 - 20px);
                }
              }
            }
          }
        }
      }
      & > .child-right {
        @include mq(1260px, max) {
          width: calc(25% - 16px);
          max-width: unset;
          min-width: unset;
          margin-right: 22px;
          & > .box-responsive > .box-container > .subject-item {
            min-width: 142px;
            min-height: 72px;
            &::before {
              width: 62px;
              height: 18px;
              top: -18px;
            }
            &::after {
              top: -10px;
              left: 53px;
              border-left: 14px solid transparent;
              border-right: 14px solid transparent;
              border-top: 14px solid #fff;
            }
            &.active::after {
              border-top-color: #2ba7df;
            }
            & > img {
              width: 24px;
              height: 24px;
              top: -5px;
              left: 12px;
            }
          }
        }
        @include mq(980px, max) {
          display: flex;
          flex-direction: row;
          margin-right: 0;
          width: 100%;
          overflow-x: auto;
          & > .box-responsive {
            width: 100%;
            position: relative;
            height: 108px;
            & > .box-container {
              width: 100%;
              position: absolute;
              overflow: auto;
              max-height: 320px;
              display: flex;
              flex-direction: row;
            }
          }
          & > .box-responsive > .box-container > .subject-item {
            margin-top: 18px !important;
            margin-bottom: 0;
            margin-right: 16px;
          }
        }
      }
    }
  }
}
