@import './ActionLink/actionLink';
@import './ActionLinksGroup/actionLinksGroup';
@import './Button/taButton';
@import './Card/card';
@import './CardTitle/cardTitle';
@import './courses/courses';
@import './List/taList';
@import './ListSearchInput/taListSearchInput';
@import './Loading/loading';
@import './Logo/logo';
@import './MenuDropdown/menuDropdown';
@import './Modals/modals';
@import './Select/taSelect';
@import './ViewTitle/viewTitle';
@import './ResponsiveTable/responsiveTable';
@import './ViewSubtitle/viewSubtitle';
@import './Badge/badge';

//Blue Button
.blueButton {
  @include blueButton();
  @media screen and (max-width: 425px) {
    width: 25% !important;
  }
  @media screen and (max-width: 350px) {
    width: 15% !important;
  }
  .buttonText {
    @include buttonText();
  }
}
.modalLine {
  border-top-style: solid;
  border-top-width: 1px;
  border-top-color: $blue;
}

.link {
  text-decoration: none;
  color: black;
  transition: all 300ms ease;
  &.primary {
    color: #2ba7df;
    &:hover {
      color: #16739c;
    }
  }
}

.noselect {
  cursor: default;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
