.reported-videos-ta-list {
  & > .ta-list-header {
    @include mq(1300px, max) {
      flex-direction: column !important;
    }
    & > .start {
      display: flex;
      flex-wrap: wrap;
      @include mq(1300px, max) {
        margin-bottom: 12px;
      }
      & > .ta-list-search {
        @include mq(821px, max) {
          margin-top: 12px;
        }
      }
      & > .affichage {
        width: fit-content;
        & > * {
          margin-left: 12px;
        }
        @include mq(1138px, max) {
          margin-top: 12px;
        }
      }
    }
    & > .end {
    }
  }
}
.reported-video-item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  @include mq(781px, max) {
    flex-direction: column;
  }
  & > .cover {
    background: #eff1f3;
    width: 238px;
    min-width: 238px;
    height: 158px;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    transition: all 300ms ease;
    @include mq(981px, max) {
      width: 142px;
      min-width: 142px;
      height: 94px;
    }
    & > img {
      width: 100%;
      transition: all 300ms ease;
    }
    & > .MuiSvgIcon-root {
      position: absolute;
      color: #ffffff;
      width: 44px;
      height: 44px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      z-index: 12;
    }
  }
  &:hover > .cover > img {
    transform: scale(1.2) rotate(2deg);
  }
  & > .infos {
    width: 100%;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    @include mq(781px, max) {
      margin-left: 0;
      margin-top: 12px;
    }
    & > .title {
      color: #0d0e0f;
      font-size: 18px;
      font-weight: 500;
      margin-bottom: 8px;
      @include mq(981px, max) {
        font-size: 14px;
      }
    }
    & > .description {
      color: #b9b9bd;
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 8px;
      @include mq(981px, max) {
        font-size: 12px;
      }
    }
  }

  & .MuiChip-root {
    border-radius: 4px;
    font-size: 10px;
    font-weight: 200;
    &.MuiChip-sizeSmall {
      height: 18px;
    }
    &.primary {
      color: #ffffff;
      background: #2ba7df;
    }
    &.dark {
      color: #ffffff;
      background: #18698d;
    }
    &.warning {
      color: #ffffff;
      background: #edc454;
    }
    &.danger {
      color: #ffffff;
      background: #ed5454;
    }
    &.lime {
      color: #ffffff;
      background: #3dec37;
    }
    &.ml {
      margin-left: 4px;
    }
  }
}

.btns-group {
  & > *:not(:first-child) {
    margin-left: 10px;
  }
}

.text-purple {
  color: #4a3c98;
  font-size: 16px;
  font-weight: 600;
  @include mq(981px, max) {
    font-size: 12px;
  }
}
