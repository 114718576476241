.ta-modal {
  & > .MuiBackdrop-root {
    background-color: rgba(0, 0, 0, 0.8);
  }
  & > .MuiDialog-container {
    & > .MuiPaper-root {
      border-radius: 20px;
      min-width: 600px;
      @include mq(775px, max) {
        min-width: 80%;
      }
      & > .MuiDialogTitle-root {
        & > .MuiTypography-root {
          display: flex;
          justify-content: space-between;
          align-items: center;
          & > .label {
            font-size: 20px;
            color: #2ba7df;
            @include mq(775px, max) {
              font-size: 16px;
            }
          }
          & > .close-btn {
            font-size: 12px;
            color: #2ba7df;
            font-weight: 600;
            cursor: pointer;
            transition: all 300ms ease;
            &:hover {
              color: #3870a3;
            }
          }
        }
      }
    }
  }
}

.add-content-modal {
  & > .MuiDialog-container {
    & > .MuiPaper-root {
      & > .MuiDialogContent-root {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 100px;
        padding-top: 20px;
        @include mq(775px, max) {
          flex-direction: column;
          align-items: center;
        }
        & > .items-group {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          @include mq(775px, max) {
            margin-bottom: 20px;
          }
          & > img {
            margin-bottom: 25px;
          }
        }
      }
    }
  }
}

.add-video-modal {
}
.request-url-button {
  background: #2ba7df;
  border: 0;
  border-radius: 15px;
  padding: 10px;
  color: white;
  cursor: pointer;
}
.MuiSwitch-root {
  & > .MuiSwitch-colorPrimary.Mui-checked {
    color: #2ba7df;
  }
  & > .MuiSwitch-colorPrimary.Mui-checked + .MuiSwitch-track {
    background-color: #2ba7df;
  }
}

.ta-form {
  & > .label {
  }
  & > .input {
    margin-top: 0;
    &.size-sm {
      margin-bottom: 4px;
      & > .MuiSelect-root {
        padding: 10.5px 14px;
      }
    }
    &.mb {
      margin-bottom: 8px;
    }
  }
  & > .ta-btns-group {
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    margin-bottom: 22px;
    & > *:not(:first-child) {
      margin-right: 12px;
    }
  }
  & .ta-btns-right {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;
    margin-top: 10px;
  }
}

.ta-modal-content-scroll {
  max-height: 480px;
  overflow-y: scroll;
}

.tests-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 32px;
  & > * {
    width: calc(100% / 4 - 12px) !important;
    margin: 0 6px !important;
    margin-bottom: 12px !important;
    padding: 6px !important;
  }
}

.test-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  & > .label {
    color: #0d0e0f;
    font-size: 18.5px;
    font-weight: 600;
    margin-bottom: 18px;
  }
  & > .ta-btn {
    width: 183px;
    padding: 6px;
  }
  & > hr {
    width: 100%;
    border: none;
    border-top: 1px solid #e2e2e4;
    margin-top: 20px;
    margin-bottom: 30px;
  }
  & > .docs-container {
    display: flex;
    flex-direction: column;
    padding: 0 18px;
    margin-bottom: 16px;
    & > .description {
      font-size: 16px;
      font-weight: 400;
      color: #0d0e0f;
      margin-bottom: 2px;
    }
    & > .doc {
      color: #838eab;
      font-size: 16px;
      font-weight: 400;
      cursor: pointer;
      transition: all 300ms ease;
      margin-bottom: 2px;
      display: flex;
      &:hover {
        color: #18698d;
        font-weight: 600;
      }
      & > .MuiSvgIcon-root {
        margin: 0 2px;
      }
    }
  }
}

.timer-modal-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 30px !important;
  & > img {
    width: 240px;
    margin: 0 auto;
    margin-bottom: 16px;
  }
  & > .label {
    color: #0d0e0f;
    font-size: 24px;
    font-weight: 600;
  }
  & > .sub-label {
    color: #a2a2a7;
    font-size: 18px;
    margin-bottom: 14px;
  }
}

.switch-classes-container {
  display: flex;
  flex-direction: column;
  & > .title {
    font-size: 14px;
    font-weight: 600;
    color: #0d0e0f;
  }
  &.progress {
    cursor: progress !important;
  }
}

.switch-classe-item {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 22px 42px;
  cursor: pointer;
  & > .texts {
    display: flex;
    flex-direction: column;
    z-index: 8;
    transform: translateX(12px);
    & > .group {
      display: flex;
      & > .number {
        font-size: 104px;
        font-weight: 600;
        line-height: 74px;
        color: #2ba7df;
      }
      & > .sup {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        color: #838eab;
      }
    }
    & > .label {
      font-size: 11px;
      color: #2ba7df;
      font-weight: 600;
      text-transform: uppercase;
    }
  }
  & > .box {
    width: 130px;
    height: 54px;
    border: 3px solid #838eab;
    border-radius: 16px;
    z-index: 4;
    margin-top: -48px;
    &::before {
      content: "";
      width: 64px;
      height: 8px;
      background: white;
      display: flex;
      margin: 0 auto;
      margin-top: -6px;
    }
    &::after {
      transition: all 300ms ease;
      content: "";
      width: 142px;
      height: 34px;
      background: #d5edf9;
      display: flex;
      margin: 0 auto;
      z-index: -1000;
      transform: translateX(-50%) translateY(24%) rotate(-3deg);
      margin-left: 61px;
    }
  }
  &:hover > .box::after {
    background: #f9ebd5;
    transform: translateX(-50%) translateY(24%) rotate(6deg);
  }
}

.switch-classes-group {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin: 42px auto;
  margin-bottom: 8px;
}

.switch-users-group {
  display: flex;
  flex-direction: column;
  justify-content: center;
  & > .switch-classe-item {
    cursor: default;
    &:hover > .box::after {
      background: #d5edf9;
      transform: translateX(-50%) translateY(24%) rotate(-3deg);
    }
    & > .texts > .group {
      & > .MuiCircularProgress-root {
        color: #2ba7df !important;
        position: absolute;
        top: 2px;
        left: -24px;
        z-index: 12;
      }
    }
  }
  & > .btns-group {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    margin-top: 12px;
    margin-bottom: 40px;
    & > * {
      margin: 8px !important;
    }
    & > .ta-btn {
      width: 160px;
      height: auto !important;
    }
  }
}

.suggestion-modal-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 30px !important;
  & > .avatar {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 18px;
    & > .MuiAvatar-root {
      width: 48px;
      height: 48px;
    }
    & > .texts {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      margin-left: 14px;
      & > .name {
        color: #757575;
        font-size: 20px;
        font-weight: 600;
      }
      & > .date {
        color: #757575;
        font-size: 12px;
      }
    }
  }
  & > .label {
    color: #18698d;
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 12px;
  }
  & > img {
    width: 100%;
    margin-bottom: 16px;
  }
  & > .title {
    color: #0d0e0f;
    font-size: 16.5px;
    font-weight: 600;
    margin-bottom: 8px;
  }
  & > .description {
    color: #a2a2a7;
    font-size: 15px;
    margin-bottom: 24px;
  }
  & > .MuiButtonBase-root {
    width: 104px;
  }
}

.delete-confirmation-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;

  & > .delete-Icon {
    font-size: 150px !important;
    color: rgb(238, 148, 96);
  }
  & > .delete-title {
    font-size: 30px;
    color: #757575;
  }
  & > .delete-description {
    font-size: 15px;
    margin-bottom: 20px;
    color: #636161;
  }
}

.dropzone {
  width: 100%;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  padding: 6px;
  margin-bottom: 8px;
  display: flex;
  &.event {
    justify-content: space-between;
  }
  &.error {
    border: 1px solid #f44336;
  }
  & > div {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  & .files-list {
    margin-left: 12px;
    color: #757575;
  }
}

.ta-btns-group {
  & > *:not(:last-child) {
    margin-right: 4px;
  }
}

.only-text {
  padding: 6px 14px !important;
}

.only-icon {
  min-width: auto !important;
}

.files-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 12px;
  &.exams {
    padding: 0 18px;
    margin-top: 0;
    margin-bottom: 16px;
  }
  & > *:not(:last-child) {
    margin-bottom: 8px;
  }
  &.last-with-margin {
    & > * {
      margin-bottom: 8px !important;
    }
  }
  & > .file {
    display: flex;
    flex-direction: row;
    width: 100%;
    & > .title {
      background: #d5edf9;
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      padding: 4px 8px;
      flex-grow: 1;
      transition: all 300ms ease;
      cursor: pointer;
      &.rounded {
        border-radius: 4px;
      }
      & > img {
        width: 20px;
        margin-right: 4px;
      }
      & > span {
        font-size: 14px;
        font-weight: 500;
        text-transform: lowercase;
        color: black;
      }
      &:hover {
        background: #80cff3;
      }
    }
    & > .ta-btn {
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
    }
  }
}

.text-ellipsis {
  & > .MuiButton-label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    display: block;
  }
}
.benefits-history-content {
  margin: 20px;
  min-width: 400px !important;
  min-height: 150px;
}
