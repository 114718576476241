.table-responsive {
  width: 100%;
  height: 350px;
  position: relative;
  & > .table-container {
    max-height: 320px;
    overflow: auto;
    position: absolute;
    width: 100%;
    // box-shadow: 0px 2px 8px rgba(245, 245, 245, 0.6) !important;
    box-shadow: none;
    padding-bottom: 20px;
  }
  & > .MuiTablePagination-root {
    margin-top: 320px;
    position: absolute;
  }
  & th,
  td {
    min-width: max-content;
    white-space: nowrap;
    border-bottom: none;
    padding: 2px 4px;
    text-align: left;
  }
  & .MuiTableCell-stickyHeader {
    background-color: #ffffff !important;
    border-bottom: 1px solid #d6d6da;
    padding: 2px 6px;
    padding-bottom: 12px;
    color: #2ba7df;
    font-size: 20px;
    font-weight: 400;
    text-align: left;
  }
  & .MuiTableBody-root {
    & .MuiTableRow-root {
      & > .MuiTableCell-root {
        color: #838383;
        font-size: 18px;
        padding: 8px;
      }
    }
  }
  & .MuiTableRow-root.MuiTableRow-hover:hover {
    // background-color: rgba(245, 245, 245, 0.6);
    color: #212121;
  }
  .tab-nav-link {
    text-decoration: none;
    color: black;
  }
  .MuiTableRow-root.MuiTableRow-hover:hover {
    background-color: #ffffff;
    & > .MuiTableCell-root {
      color: #212121;
    }
  }
}
