.contents-list {
  padding: 12px !important;
  max-height: none !important;
  height: auto !important;
  overflow-y: visible !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 12px;
}
.content-item {
  width: 100%;
  padding: 20px;
  box-shadow: 0px 2px 8px rgb(179 208 232 / 60%);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all 300ms ease;
  cursor: pointer;
  position: relative;
  @include mq(980px, max) {
    flex-direction: column;
    align-items: flex-start;
  }
  &:hover {
    box-shadow: 0px 4px 18px 2px rgb(166 196 220 / 60%);
    background: #eef2fa;
  }
  & > .title {
    font-size: 20px;
    font-weight: 600;
    color: #191836;
    flex-grow: 1;
    display: flex;
    align-items: center;
    @include mq(1206px, max) {
      font-size: 14px;
    }
    & > .MuiSvgIcon-root {
      color: #ed5454;
      font-size: 24px !important;
      margin-right: 20px;
    }
  }
  & > .duration {
    font-size: 15px;
    width: 100px;
    margin-left: 20px;
    @include mq(980px, max) {
      margin-left: 45px !important;
    }
  }
  & > .MuiSvgIcon-root {
    color: #9da6b7;
    width: 20px;
    @include mq(980px, max) {
      position: absolute;
      right: 12px;
    }
  }
  & .MuiChip-root {
    border-radius: 4px;
    font-size: 14px;
    font-weight: 200;
    @include mq(980px, max) {
      margin-left: 45px !important;
    }
    &.MuiChip-sizeSmall {
      height: 22px;
    }
    &.primary {
      color: #ffffff;
      background: #2ba7df;
    }
    &.dark {
      color: #ffffff;
      background: #18698d;
    }
    &.warning {
      color: #ffffff;
      background: #edc454;
    }
    &.danger {
      color: #ffffff;
      background: #ed5454;
    }
    &.lime {
      color: #ffffff;
      background: #3dec37;
    }
    &.ml {
      margin-left: 4px;
    }
  }
}

.chapter-title {
  font-size: 24px;
  font-weight: 600;
  color: #18698d;
}

.contents-ta-list {
  & > .MuiCardHeader-root {
    & > .MuiCardHeader-content {
      & > .ta-tabs {
        @include mq(900px, max) {
          flex-wrap: wrap;
        }
        & > .ta-tabs-item {
          @include mq(1206px, max) {
          }
        }
      }
    }
  }
  & > .ta-list-header {
    @include mq(1336px, max) {
      flex-direction: column !important;
    }
    & > .start {
      @include mq(1336px, max) {
        margin-bottom: 12px;
      }
    }
    & > .end {
      @include mq(900px, max) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        & > *:not(:first-child) {
          margin-left: 0 !important;
          margin-top: 12px;
        }
      }
    }
  }
}
.contents-section {
  width: 100%;
  padding: 20px;
  box-shadow: 0px 2px 8px rgb(179 208 232 / 60%);
  border-radius: 6px;
  background: #8bbbd7;
  //padding-bottom: 20px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 20px;
  font-weight: 600;
  color: #191836;
  @include mq(1206px, max) {
    font-size: 14px;
  }
}

.contents-section-droppable {
  width: 100%;
  & > *:not(:last-child) {
    margin-bottom: 20px;
  }
}
