.card-title {
  color: $blue;
  font-size: 14px;
  font-weight: 500;
  &.dark-blue {
    color: $darker_blue;
  }
  &.orange {
    color: $orange;
  }
  &.black {
    color: black;
  }
  &.primary {
    color: #2ba7df;
  }
  &.secondary {
    color: #2ba7df;
  }
  &.success {
    color: #50bf88;
  }
  &.warning {
    color: #edc454;
  }
  &.danger {
    color: #ed5454;
  }
  &.underlined {
    &::before {
      content: '';
      width: 100px;
      height: 2px;
      background: #40ccfe;
      position: absolute;
      margin-top: 17px;
      border-radius: 70px;
    }
  }
  &.last-courses {
    &::before {
      width: 194px;
    }
  }
  &.free-videos {
    &::before {
      width: 132px;
    }
  }
  &.forum-title {
    &::before {
      background: #40fe86;
      width: 60px;
    }
  }
}

.MuiSvgIcon-root {
  font-size: 20px !important;
  &.orange {
    color: $orange !important;
  }
  &.black {
    color: black;
  }
}
