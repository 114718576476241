.chapters-list {
  padding: 12px !important;
  max-height: none !important;
  height: auto !important;
  overflow-y: visible !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 12px;
}
.chapter-item {
  width: 100%;
  padding: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  box-shadow: 0px 2px 8px rgb(179 208 232 / 60%);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all 300ms ease;
  cursor: pointer;
  &:hover {
    box-shadow: 0px 4px 18px 2px rgb(166 196 220 / 60%);
    background: #eef2fa;
  }
  & > .title {
    font-size: 20px;
    font-weight: 600;
    color: #191836;
    flex-grow: 1;
  }
  & > .date {
    font-size: 15px;
    color: #a2a2a7;
    width: 228px;
  }
  & > .MuiSvgIcon-root {
    color: #9da6b7;
    width: 20px;
  }
  & .MuiButtonBase-root {
    color: #9da6b7;
  }
}

.ta-tabs {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  border-bottom: 2px solid #f1f1f1;
  padding-bottom: 14px;
  margin-bottom: 12px;
  & > .ta-tabs-item {
    color: #8bb4c6;
    font-size: 18px;
    font-weight: 400;
    margin-right: 20px;
    transition: all 300ms ease;
    cursor: pointer;
    &.active {
      color: #18698d;
      font-weight: 600;
      &::after {
        content: '';
        width: 100%;
        height: 3px;
        background: #18698d;
        display: flex;
        border-radius: 50px;
      }
    }
  }
  & > :last-child {
    margin-right: 0;
  }
}

.chapters-ta-list {
  & > .MuiCardHeader-root {
    & > .MuiCardHeader-content {
      & > .ta-tabs {
        @include mq(900px, max) {
          flex-wrap: wrap;
        }
        & > .ta-tabs-item {
          @include mq(1206px, max) {
          }
        }
      }
    }
  }
  & > .ta-list-header {
    @include mq(1206px, max) {
      flex-direction: column !important;
    }
    & > .start {
      @include mq(1206px, max) {
        margin-bottom: 12px;
      }
      & > .ta-list-search {
        @include mq(820px, max) {
          max-width: 150px;
        }
        @include mq(760px, max) {
          max-width: 98px;
        }
      }
    }
    & > .end {
      @include mq(820px, max) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        & > *:not(:first-child) {
          margin-left: 0 !important;
          margin-top: 12px;
        }
      }
    }
  }
  & > .chapters-list {
    & > .chapter-item {
      @include mq(880px, max) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        position: relative;
      }
      & > .title {
        @include mq(1206px, max) {
          font-size: 14px;
        }
      }
      & > .date {
        @include mq(1206px, max) {
          font-size: 14px;
        }
      }
      & > .MuiSvgIcon-root {
        @include mq(880px, max) {
          position: absolute;
          right: 12px;
        }
      }
    }
  }
}
