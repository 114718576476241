.video-information {
  width: 100%;
  height: 100%;
  min-height: 180px;
  opacity: 1;
  background-color: #ffffff;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  line-height: 10px;
  column-gap: normal;
  border-radius: 10px;
  .container {
    margin-left: 29px;
    margin-right: 20px;
    @include mq(960px, max) {
      display: inline-flex;
      flex-direction: column;
      padding-top: 0px;
      margin-left: 5px;
      column-gap: normal;
    }
  }
  .title {
    color: #18698d;
    opacity: 1;
    font-family: tajawal;
    font-size: 18px;
    font-weight: Bold;
    letter-spacing: 0;
    margin-top: 40px;
    @include mq(960px, max) {
      margin-top: 15px;
    }
  }
  .avatar {
    width: 42px;
    height: 42px;
    border: 1px solid #707070;
    opacity: 1;
  }
  .instructorName {
    color: #0d0e0f;
    font-family: tajawal;
    font-size: 16px;
    font-weight: Bold;
    letter-spacing: 0;
    opacity: 1;
  }
  .description {
    color: #a2a2a7;
    font-family: tajawal;
    font-size: 14px;
    letter-spacing: 0;
    opacity: 1;
  }
  .details {
    flex: 1;
    flex-direction: row;
    justify-content: space-between;
    margin: 5px;
  }
  .detailsTeacher {
    display: flex;
    justify-content: space-between;
  }
  .box {
    border: none;
    box-shadow: none;
  }
  .group {
    box-shadow: none;
    max-width: 280px;
    display: flex;
    margin-top: 10px;
  }
  .buttonUpload {
    overflow: hidden;
    background: #2ba7df29;
    opacity: 0.2;
    color: #0d0e0f;
    opacity: 1;
    border-radius: 5px 0px 0px 5px;
    max-width: 180px;
  }
  .textUpload {
    font-family: tajawal;
    font-size: 12px;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .iconPdf {
    color: #ed5454;
  }

  .buttonDownload {
    background: #40bcf9;
    color: #ffffff;
    font-family: Tajawal;
    font-size: 12px;
    text-align: center;
    opacity: 1;
    border-radius: 0px 5px 5px 0px;
    max-width: 120px;
    text-transform: capitalize;
    &:hover {
      background-color: #40bcf9;
      opacity: 0.8;
    }
  }
}
