.view-subtitle {
  color: $blue3;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 20px;
  margin-top: 20px;
  @include mq(780px, max) {
    font-size: 16px;
  }
  &.margin {
    margin-top: 5px;
    margin-bottom: 25px;
  }
  &.no-space {
    margin-top: 0px;
    margin-bottom: 0px;
  }
}
