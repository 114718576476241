.suggestions {
  & > img {
    width: 576px;
    margin: 0 auto;
    margin-bottom: 24px;
    @include mq(1199px, max) {
      width: 476px;
    }
    @include mq(999px, max) {
      width: 376px;
    }
    @include mq(799px, max) {
      width: 276px;
    }
    @include mq(699px, max) {
      display: none;
    }
  }
}

.suggestions-list {
  padding: 12px !important;
  max-height: none !important;
  height: auto !important;
  overflow-y: visible !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 12px;
}

.suggestion-item {
  width: 100%;
  padding: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  box-shadow: 0px 2px 8px rgb(179 208 232 / 60%);
  border-radius: 6px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all 300ms ease;
  cursor: pointer;
  position: relative;
  @include mq(1133px, max) {
    flex-direction: column;
    align-items: flex-start;
    & > * {
      margin-bottom: 4px;
    }
  }
  &:hover {
    box-shadow: 0px 4px 18px 2px rgb(166 196 220 / 60%);
    background: #eef2fa;
  }
  & > .MuiButtonBase-root {
    @include mq(1133px, max) {
      margin-bottom: 4px;
    }
  }
  & > .texts-group {
    display: flex;
    flex-direction: column;
    color: #191836;
    & > .label {
      font-size: 20px;
      font-weight: 600;
      @include mq(1349px, max) {
        font-size: 14px;
      }
    }
    & > .description {
      font-size: 16px;
      @include mq(1349px, max) {
        font-size: 13px;
      }
    }
  }
  & > .avatar-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    width: 250px;
    @include mq(1349px, max) {
      width: 158px;
    }
    & > .name {
      margin-left: 12px;
      color: #757575;
      font-size: 16px;
      font-weight: 600;
      @include mq(1349px, max) {
        font-size: 14px;
      }
    }
  }
  & > .date {
    font-size: 15px;
    color: #a2a2a7;
    width: 180px;
    @include mq(1349px, max) {
      font-size: 13px;
      width: 130px;
    }
  }
  & > .MuiSvgIcon-root {
    color: #9da6b7;
    width: 20px;
    @include mq(1133px, max) {
      position: absolute;
      right: 12px;
    }
  }
}
