.header-btns-group {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 32px;
  padding: 0 16px;
  @include mq(828px, max) {
    justify-content: flex-start;
  }
}

.next-courses-item {
  flex-wrap: wrap;
  & > .text-muted {
    color: #b3b3b7;
    margin-left: 8px;
    margin-right: 8px;
  }
  & > svg {
    color: #2ba7df;
  }
}

.exams-links-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  @include mq(1160px, max) {
    flex-direction: column;
    align-items: flex-start;
    & > .actions-group {
      margin-left: 0 !important;
    }
    & > img {
      display: none;
    }
  }
  & > img {
    margin-right: 70px;
    @include mq(1248px, max) {
      margin-right: 12px;
    }
  }
  & > .texts-group {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    & > .title {
      color: #0d0e0f;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 2px;
    }
    & > .date {
      color: #a8a9a9;
      font-size: 16px;
    }
  }
  & > .actions-group {
    margin-left: 24px;
  }
}

.forumWidget {
  .forum-title {
    text-decoration: underline;
  }
  .post-title {
    color: #18698d;
    font-size: 14px;
    font-family: tajawal;
    font-weight: bold;
  }
}
