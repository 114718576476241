.ta-list-search {
  & > .MuiInputBase-root {
    height: 32px !important;
    & > div > .MuiSvgIcon-root {
      color: #c4c4c4;
    }
  }
  & > .MuiOutlinedInput-root {
    border-radius: 0;
  }
}
