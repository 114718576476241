.exams-list {
  padding: 12px !important;
  max-height: none !important;
  height: auto !important;
  overflow-y: visible !important;
  display: flex !important;
  flex-direction: row !important;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 12px;
}
.exam-item {
  width: calc(100% / 3 - 14px);
  padding: 12px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 20px;
  box-shadow: 0px 2px 8px rgb(179 208 232 / 60%);
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  transition: all 300ms ease;
  cursor: pointer;
  @include mq(1180px, max) {
    width: calc(100% / 2 - 11px);
  }
  @include mq(940px, max) {
    width: 100%;
  }
  & > .title {
    font-size: 24px;
    font-weight: 600;
    color: #333333;
    @include mq(980px, max) {
      font-size: 18px;
    }
  }
  & > .date {
    font-size: 18px;
    color: #333333;
    @include mq(980px, max) {
      font-size: 14px;
    }
  }
  & > img {
    margin: 0 auto;
    width: 80px;
    margin-top: 32px;
    @include mq(980px, max) {
      width: 70px;
      margin-top: 12px;
    }
  }
  & > .number {
    font-size: 38px;
    font-weight: 600;
    color: #838eab;
    margin: 0 auto;
    margin-bottom: 32px;
    @include mq(980px, max) {
      font-size: 26px;
      margin-bottom: 4px;
    }
  }
  & > .btns-group {
    margin: 0 auto;
    & > *:not(:first-child) {
      margin-left: 10px;
    }
  }
  &:hover {
    box-shadow: 0px 4px 18px 2px rgb(166 196 220 / 60%);
  }
}

.exam-details {
  max-height: none !important;
  height: auto !important;
  overflow-y: visible !important;
  display: flex !important;
  flex-direction: column !important;
  margin-bottom: 12px;
  padding: 0 12px;
  & > * {
    padding-bottom: 0 !important;
    margin-bottom: 0 !important;
    border-bottom: none !important;
  }
  & > div {
    padding: 0 8px;
    padding-top: 20px;
    border-top: 1px solid #d0d0d3;
  }
  & > div > .name {
    display: flex;
    font-size: 30px;
    font-weight: 600;
    color: #18698d;
    margin-bottom: 32px !important;
    @include mq(1000px, max) {
      font-size: 18px;
    }
  }
  & > div > .row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    @include mq(880px, max) {
      flex-direction: column;
    }
    & > .col {
      display: flex;
      flex-direction: column;
      width: calc(50% - 12px);
      @include mq(880px, max) {
        width: 100%;
      }
    }
  }
  & .title {
    font-size: 18px;
    font-weight: 600;
    color: #2ba7df;
    @include mq(1000px, max) {
      font-size: 16px;
    }
  }
  & .description {
    font-size: 24px;
    font-weight: 400;
    color: #0d0e0f;
    margin-bottom: 24px;
    @include mq(1000px, max) {
      font-size: 14px;
    }
  }
  & .link {
    text-decoration: underline;
    color: #838eab;
    display: flex;
    align-items: center;
    font-size: 20px;
    margin-bottom: 24px;
    transition: all 300ms ease;
    @include mq(1000px, max) {
      font-size: 14px;
    }
    &:hover {
      color: #18698d;
    }
  }
  & .modal-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #2ba7df;
    border-radius: 4px;
    padding: 12px 20px;
    width: 260px;
    margin-bottom: 20px;
    box-shadow: 0 10px 18px #0000001c;
    transition: all 300ms ease;
    cursor: pointer;
    @include mq(1000px, max) {
      width: 200px;
      padding: 8px;
    }
    & > img {
      width: 58px;
      margin-right: 20px;
      @include mq(1000px, max) {
        width: 38px;
        margin-right: 18px;
      }
    }
    & > div {
      display: flex;
      flex-direction: column;
      color: #838eab;
      & > .primary-text {
        font-size: 30px;
        font-weight: 600;
        @include mq(1000px, max) {
          font-size: 18px;
        }
      }
      & > .secondary-text {
        font-size: 18px;
        margin-bottom: -6px;
        @include mq(1000px, max) {
          font-size: 14px;
        }
      }
    }
    &:hover {
      box-shadow: 0 2px 4px #00000045;
    }
  }
}

.exams-ta-list {
  & > .ta-list-header {
    @include mq(690px, max) {
      flex-direction: column !important;
    }

    & > .start {
      @include mq(690px, max) {
        margin-bottom: 12px;
      }
    }
    & > .end {
      @include mq(690px, max) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;
        & > *:not(:first-child) {
          margin-left: 0 !important;
          margin-top: 12px;
        }
      }
    }
  }
}
.exams-page {
  & .ta-list-header {
    justify-content: flex-end !important;
    align-items: center;
    gap: 2.2rem;
  }
  .exams-filter-btn {
    & .MuiButton-label {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.6rem;
      & span {
        font-size: 1rem;
        font-weight: 500;
        text-transform: capitalize;
      }
    }
  }
  .exams-filter-content {
    & .MuiCollapse-wrapperInner {
      display: flex;
      flex-direction: column;
      align-items: center;
      & > div {
        display: flex;
        width: 100%;
        padding: 1rem 1rem 2rem;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 1.4rem;

        & .MuiTypography-root {
          font-weight: 500 !important;
        }
        & .filter-wrapper {
          width: 100%;
          gap: 1rem;

          & .filter {
            display: flex;
            flex-direction: column;
            gap: 0.5rem;
            flex: 1;

            & .MuiInputBase-root {
              max-width: 16.6rem !important;
              margin-right: 0 !important;
              margin-left: 0 !important;
            }

            & .MuiFormControl-root {
              margin-right: 0 !important;
              margin-left: 0 !important;
            }

            & .field-wrapper {
              & input {
                padding: 13px 14px !important;
                width: 100% !important;
              }
            }
          }
        }
        #multiple-name {
          min-width: 16.6rem !important;
          max-width: 16.6rem !important;
          @include mq(880px, max) {
            min-width: 10.6rem !important;
          }
          @include mq(713px, max) {
            min-width: 7rem !important;
          }
          @include mq(440px, max) {
            min-width: 88% !important;
            max-width: 12.6rem !important;
          }
        }
        .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
          border-color: #2ba7df !important;
        }
        .MuiOutlinedInput-notchedOutline {
          border-color: #e8e8e8 !important;
          border-radius: 12px !important;
        }
        .date-range-picker-wrapper {
          padding: 0 !important;
          gap: 23px !important;
          @include mq(440px, max) {
            flex-direction: column;
          }
          & .field-wrapper {
            width: 100% !important;

            & input {
              width: 100% !important;
              height: 20px !important;
            }
          }
        }
        & .filter:first-child {
          gap: 0rem !important;
          flex-basis: 23%;
          display: flex;
          flex-direction: column;

          @include mq(1214px, max) {
            flex: 1;
          }
          & .MuiFormControl-root {
            width: 100% !important;
            margin-right: 0;
            margin-left: 0;
          }
        }
        & .filter:not(:first-child) {
          flex-basis: 36.5%;
          min-width: 31rem;
          display: flex;
          flex-direction: column;
          gap: 0.5rem;
          flex-wrap: wrap;
          flex: 1;
          @include mq(880px, max) {
            min-width: 20.6rem !important;
          }
          @include mq(713px, max) {
            min-width: 100% !important;
          }
        }
      }

      & .filters-action {
        gap: 1rem;
        justify-content: center;

        & button:first-child {
          background: #2ba7df;
          color: #fff;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }

        & button:last-child {
          background: #fff;
          box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
      }
    }
  }
}
.MuiMenu-list {
  & > li {
    @include mq(480px, max) {
      font-size: 13px !important;
    }
  }
}
