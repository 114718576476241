@import './Chapters/chapters';
@import './Contents/contents';
@import './Events/events';
@import './Exams/exams';
@import './Home/home';
@import './Magazines/magazines';
@import './Qcm/qcm';
@import './MySuggestions/mySuggestions';
@import './Profile/profile';
@import './ReportedVideos/reportedVideos';
@import './Subjects/subjects';
@import './Suggestions/suggestions';
@import './Tasks/tasks';
@import './Videos/videos';
@import './CodeCoupon/codeCoupon';
@import './Incomes/Widget/widget';
@import './Incomes/SelectIncomesYearDropdown/selectIncomesYearDropdown';

.ta-row {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-bottom: 40px;
  .flex-container {
    display: flex;
    justify-content: center;
    .simple-title {
      color: $blue3;
      font-weight: bold !important;
      font-size: 16px !important;
    }
    .left {
      min-width: 67%;
      margin: 5px;
      box-shadow: 0px 3px 10px #2ba7df29;
      border-radius: 10px;
      @include mq(1024px) {
        min-width: 60%;
      }
    }
    .right {
      min-width: 30%;
      margin: 5px;
      box-shadow: 0px 3px 10px #2ba7df29;
      border-radius: 10px;
      @include mq(1024px) {
        min-width: 20%;
      }
    }

    .left-banque {
      min-width: 50%;
      margin-right: 15px;
    }
    .right-banque {
      min-width: 40%;
      text-align: center;
      display: block;
    }
    .nb-point {
      color: #ed5454;
      text-align: center;
      font-weight: bold;
      letter-spacing: 0px;
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 32px;
    }
  }
  .bottom {
    margin: 5px;
    box-shadow: 0px 3px 10px #2ba7df29;
    border-radius: 10px;
  }
  .subject-column {
    width: auto;
    height: 400px;
    background: transparent;
    &.subject-right-margin {
      margin-right: 260px;
      @include mq(948px, max) {
        margin-right: 0px;
        width: 100% !important;
      }
    }
  }
  .ta-column {
    width: auto;
    // height: 400px;
    // background: #cecede;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: start;
    padding: 12px;
    padding-right: 16px;
    padding-left: 0;
    @include mq(1200px, max) {
      width: 100%;
    }
    // &:first-child {
    //   height: 4000px;
    // }
    @include mq(948px, max) {
      padding-right: 0 !important;
    }
    &.right-margin {
      margin-right: 236px;
      @include mq(948px, max) {
        margin-right: 0;
      }
    }
    &.assistance-right-margin {
      margin-right: 20px;
      @include mq(948px, max) {
        margin-right: 0;
      }
    }
    &.video-right-margin {
      margin-right: 20px;
    }
    &.fixed {
      width: 240px;
      position: fixed;
      // top: 145px;
      top: 68px;
      padding-right: 4px;
      @include mq(1200px, max) {
        width: 100%;
        position: relative;
        top: unset;
        right: unset;
        display: none;
      }
      & > :not(:first-child) {
        margin-top: 16px;
      }
      //   width: 240px;
      //   position: absolute;
      //   top: 0;
      //   right: 0;
      &.right {
        right: 20px;
      }
    }

    & > .child-left,
    .child-right,
    .child-full {
      margin-bottom: 16px;
      & > :not(:first-child) {
        margin-top: 16px;
      }
    }
    & > .child-left {
      box-sizing: border-box;
      width: 70%;
      margin-right: 16px;
      @include mq(948px, max) {
        width: 100% !important;
        margin-right: 0;
      }
    }
    & > .child-right {
      box-sizing: border-box;
      width: calc(30% - 16px);
      @include mq(948px, max) {
        width: 100% !important;
      }
    }
    & > .child-full {
      box-sizing: border-box;
      width: 100%;
    }
    &.childrens-inverse {
      flex-direction: row-reverse;
      & > .child-left {
        margin-right: 0;
      }
      & > .child-right {
        margin-right: 16px;
        @include mq(948px, max) {
          margin-right: 0;
        }
      }
    }
    .ta-subject {
      display: flex;
      flex-direction: row;
      text-align: justify;
      -ms-text-justify: distribute-all-lines;
      text-justify: distribute-all-lines;
      flex-wrap: wrap;
    }
    .skeleton {
      width: 100%;
      height: 100%;
    }
    .assistance {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      text-align: justify;
      -ms-text-justify: distribute-all-lines;
      text-justify: distribute-all-lines;
      flex-wrap: wrap;
    }
    .assistance-modal {
      width: 700px;
      height: 400px;
      background-color: red;
    }
    &.layout-1 {
      & > .child-left {
        width: 70%;
      }
      & > .child-right {
        width: calc(30% - 16px);
      }
    }
    &.layout-2 {
      & > .child-left {
        width: 60%;
        @include mq(1380px, max) {
          width: calc(50% - 8px);
        }
      }
      & > .child-right {
        width: calc(40% - 16px);
        @include mq(1380px, max) {
          width: calc(50% - 8px);
        }
      }
    }
    &.layout-profile {
      & > .child-left {
        width: 70%;
        @include mq(1380px, max) {
          width: calc(66% - 8px);
        }
      }
      & > .child-right {
        width: calc(30% - 16px);
        @include mq(1380px, max) {
          width: calc(34% - 8px);
        }
      }
    }
    &.layout-home {
      & > .child-left {
        width: 70%;
        @include mq(1380px, max) {
          width: calc(64% - 8px);
        }
      }
      & > .child-right {
        width: calc(30% - 16px);
        @include mq(1380px, max) {
          width: calc(36% - 8px);
        }
      }
    }
    &.layout-subjects-details {
      flex-wrap: nowrap;
      @include mq(1200px, max) {
        margin-right: 0;
        padding-right: 0;
      }
      @include mq(980px, max) {
        flex-wrap: wrap;
      }
      & > .child-left {
        width: 100%;
      }
      & > .child-right {
        max-width: fit-content;
        min-width: min-content;
        @include mq(980px, max) {
          width: 100%;
          max-width: unset;
          min-width: unset;
        }
      }
    }
    &.layout-subjects {
      flex-wrap: nowrap;
      padding-left: 16px;
      @include mq(1200px, max) {
        margin-right: 0;
        padding-right: 0;
      }
      @include mq(980px, max) {
        flex-wrap: wrap;
      }
      & > .child-left {
        width: 100%;
      }
      & > .child-right {
        max-width: fit-content;
        min-width: min-content;
        margin-right: 38px;
        @include mq(980px, max) {
          width: 100%;
          max-width: unset;
          min-width: unset;
        }
      }
    }
    &.layout-dash {
      padding-left: 16px;
      padding-top: 16px;
      @include mq(948px, max) {
        padding-right: 16px !important;
      }
      @include mq(828px, max) {
        padding-right: 0 !important;
        padding-left: 0 !important;
      }
      & > .child-left {
        width: 55%;
        @include mq(1380px, max) {
          width: calc(55% - 8px);
        }
      }
      & > .child-right {
        width: calc(45% - 16px);
        @include mq(1380px, max) {
          width: calc(45% - 8px);
        }
      }
    }
  }
}

.full-page {
  max-width: 1592px;
  margin: 0 auto;
}

.ta-toolbar {
  min-height: 44px !important;
}
