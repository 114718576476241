@import './ProfileWidget/profileWidget';
@import './SecurityWidget/securityWidget';
@import './WalletWidget/walletWidget';

.profile-page {
  & > .ta-row {
    & > .ta-column {
      @include mq(1200px, max) {
        &.fixed {
          display: flex;
          right: unset !important;
          padding-right: 0 !important;
          padding-top: 0 !important;
          & > :first-child {
            display: none;
          }
          & > .security-widget {
            margin-top: 0 !important;
            // & > .MuiCardContent-root > .child-full {
            //   & > .ta-btn {
            //     max-width: 200px;
            //   }
            // }
          }
        }
        &.right-margin {
          margin-right: 0 !important;
          padding-right: 0 !important;
          padding-bottom: 0 !important;
        }
      }
      @include mqs(1199px, 400px) {
        &.fixed > .security-widget > .MuiCardContent-root > .child-full {
          & > .ta-btn {
            max-width: 200px;
          }
        }
      }
    }
  }
}
