.ta-select {
  & > .MuiOutlinedInput-root {
    border-radius: 0 !important;
  }
  & > .MuiInputBase-root {
    height: 32px !important;
    min-width: 120px;
    & > .MuiSelect-root {
      padding: 6px 12px;
    }
    & > .MuiSvgIcon-root {
      top: calc(50% - 10px);
      color: #363636;
      transform: rotate(90deg);
    }
  }
  & > .MuiFormLabel-root {
    transform: translate(12px, 8px) scale(1);
  }
}

.MuiPopover-paper {
  box-shadow: 0px 2px 8px rgb(179 208 232 / 60%) !important;
}
