.year-select {
  position: relative;

  & > select {
    cursor: pointer;
    appearance: none;
    background: #ffffff;
    border-radius: 8px;
    border: 1px solid #dddddd;
    color: #a2a2a7;
    font-family: inherit;
    font-size: 20px;
    font-style: normal;
    font-weight: normal;
    height: 31px;
    line-height: 23px;
    padding: 0px 36px;
    width: 185px;
    outline: none;
  }
  .year-select *:focus {
    outline: none;
  }
}
