.ta-list {
  & > .ta-list-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    & > .start,
    & > .end {
      & > *:not(:first-child) {
        margin-left: 12px;
      }
    }
  }
  & > .ta-list-body {
    display: flex;
    flex-direction: column;
    max-height: 640px;
    height: 640px;
    overflow-y: scroll;
    scroll-behavior: smooth;
    & > *:not(:last-child) {
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 3px solid #8bbbd7;
    }
    & .MuiPaginationItem-page.Mui-selected {
      background-color: rgb(43 167 223);
      border: none;
      color: white;
    }
  }
}
