.action-link {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  &.text-muted {
    color: $grey;
    transition: all 300ms ease;
    font-size: 12px;
    &:hover {
      color: $grey_dark;
    }
  }
  & > .MuiSvgIcon-root {
    font-size: 20px !important;
  }
  &.full-width {
    flex-direction: row-reverse;
    width: 100%;
    & > .MuiSvgIcon-root {
      margin-right: 4px;
    }
  }
}
