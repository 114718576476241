.tasks-responsive {
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  height: 600px;
}
.tasks-container {
  display: flex;
  position: absolute;
  width: 100%;
}

.tasks-column {
  margin: 8px;
  border-radius: 12px;
  // width: 242px;
  width: calc(100% / 3);
  min-width: 242px;
  display: flex;
  flex-direction: column;
  background: #e0effa;
  & > .title {
    padding: 8px;
    color: #18698d;
    font-size: 22px;
    font-weight: 600;
  }
  & > .tasks-list {
    padding: 8px;
    transition: background-color 0.2s ease;
    flex-grow: 1;
    min-height: 100px;
  }
}

.task {
  border-radius: 4px;
  padding: 8px;
  margin-bottom: 8px;
  transition: background-color 0.2s ease;
  background-color: white;
  display: flex;
  flex-direction: column;
  & > .chips-group {
    display: flex;
    margin-bottom: 2px;
    & > * {
      margin-right: 4px;
    }
    & > .MuiChip-root {
      width: fit-content;
      height: 18px;
      font-size: 10px;
      border-radius: 4px;
      margin-bottom: 4px;
      &.Critique {
        background: #ed5454;
        color: white;
      }
      &.Urgente {
        background: #edc454;
        color: white;
      }
      &.Important {
        background: #2ba7df;
        color: white;
      }
      &.new {
        background: #3dec37;
        color: white;
      }
      &.seen,
      &.hight {
        background: #2ba7df;
        color: white;
      }
      &.veryhight {
        background: #edc454;
        color: white;
      }
    }
  }

  & > .title {
    font-weight: 600;
    margin-bottom: 6px;
  }
  & > .description {
    color: #7d7d7d;
    font-size: 10px;
  }
  & > .date {
    display: flex;
    align-items: center;
    font-weight: 600;
    font-size: 14px;
    & > .MuiSvgIcon-root {
      margin-right: 2px;
      width: 18px;
    }
    &.success {
      color: #50bf88;
    }
    &.muted {
      color: #838eab;
    }
  }
}

.task-event-modal-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  & > .title {
    font-size: 18px;
    color: #0d0e0f;
    margin-top: 12px;
    font-weight: 600;
  }
  & > .description {
    font-size: 14px;
    color: #adadb2;
    margin-top: 12px;
    margin-bottom: 0;
  }
}

.task-event-modal-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  & > .badges {
    display: flex;
    & > *:not(:last-child) {
      margin-right: 6px;
    }
    & > .MuiChip-root {
      border-radius: 4px;
      height: 20px;
      font-size: 10px;
      color: #ffffff;
      &.primary {
        background: #2ba7df;
      }
      &.purple {
        background: #4a3c98;
      }
      &.danger {
        background: #ed5454;
      }
    }
  }
  & > .title {
    font-size: 18px;
    color: #0d0e0f;
    margin-top: 12px;
    font-weight: 600;
  }
  & > .description {
    font-size: 14px;
    color: #adadb2;
    margin-top: 12px;
    margin-bottom: 0;
  }
  & > .MuiDivider-root {
    margin: 18px 0;
  }
  & > .MuiFormControl-root {
    margin-bottom: 18px;
  }
  .label {
    color: #18698d;
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 6px;
  }
  .split-childs {
    display: flex;
    justify-content: space-between;
    & > * {
      width: calc(100% / 2 - 8px);
    }
  }
  .text-muted {
    color: #838eab;
  }
  .strong {
    font-size: 13px;
    font-weight: 600;
    color: #0d0e0f;
  }
  .list {
    display: flex;
    flex-direction: column;
    .MuiSvgIcon-root {
      font-size: 10px !important;
      color: #4a3c98;
    }
  }
}
